<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            type="year"
            label="년도"
            name="year"
            v-model="searchParam.year"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="개인별 직무스트레스 평가 목록"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="suffixTitle">
        <q-icon name="sentiment_satisfied_alt" class="text-green" style="font-size:1.3em !important;font-weight:400;"/>
        <font style="font-size:0.9em;font-weight:400;">: 정상군&nbsp;&nbsp;</font>
        <q-icon name="sentiment_very_dissatisfied" class="text-negative" style="font-size:1.3em !important;font-weight:400;"/>
        <font style="font-size:0.9em;font-weight:400;">: 관리대상군</font>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="props.row[col.name] === 'Y'">
          <q-icon name="sentiment_very_dissatisfied" class="text-negative" style="font-size:1.3em !important;font-weight:400;"/>
        </template>
        <template v-else-if="props.row[col.name] === 'N'">
          <q-icon name="sentiment_satisfied_alt" class="text-green" style="font-size:1.3em !important;font-weight:400;"/>
        </template>
        <template v-else></template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'jobStressAssessment',
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: null,
        userId: '',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: false,
            style: 'width: 80px',
          },
          {
            name: 'planYear',
            field: 'planYear',
            label: '년도',
            align: 'center',
            sortable: false,
            style: 'width: 80px',
          },
          {
            name: 'jobStressPlanName',
            field: 'jobStressPlanName',
            label: '평가계획명',
            align: 'left',
            sortable: false,
            style: 'width: 200px',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '평가자',
            style: 'width:80px',
            align: "center",
            sortable: false,
            type: 'link'
          },
          {
            name: '0',
            field: '0',
            label: '물리적환경',
            child: [
              {
                name: 'physicalEnv',
                field: 'physicalEnv',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'physicalEnvScope',
                field: 'resultScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '1',
            field: '1',
            label: '직무요구',
            child: [
              {
                name: 'jobDemand',
                field: 'jobDemand',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'jobDemandScope',
                field: 'jobDemandScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '2',
            field: '2',
            label: '직무자율',
            child: [
              {
                name: 'jobAutonomy',
                field: 'jobAutonomy',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'jobAutonomyScope',
                field: 'jobAutonomyScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '3',
            field: '3',
            label: '관계갈등',
            child: [
              {
                name: 'relationship',
                field: 'relationship',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'relationshipScope',
                field: 'relationshipScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '4',
            field: '4',
            label: '직무불안정',
            child: [
              {
                name: 'jobInsecurity',
                field: 'jobInsecurity',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'jobInsecurityScope',
                field: 'jobInsecurityScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '5',
            field: '5',
            label: '조직체계',
            child: [
              {
                name: 'organizational',
                field: 'organizational',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'organizationalScope',
                field: 'organizationalScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '6',
            field: '6',
            label: '보상부적절',
            child: [
              {
                name: 'inadequate',
                field: 'inadequate',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'inadequateScope',
                field: 'inadequateScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '7',
            field: '7',
            label: '직장문화',
            child: [
              {
                name: 'workplace',
                field: 'workplace',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'workplaceScope',
                field: 'workplaceScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.jobStress.user.list.url
      // code setting
      // 로그인자 본인것만 작성
      this.$set(this.searchParam, 'userId', this.$store.getters.user.userId)
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = '개인별 직무스트레스 평가 상세';
      this.popupOptions.param = {
        heaJobStressAssessmentUserId: row.heaJobStressAssessmentUserId
      };
      this.popupOptions.target = () => import(`${'./jobStressAssessmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
